import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

export const Background = styled(Container)`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  background: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  min-height: 80vh;
`;

export const Body = styled(Row)`
  min-height: 80vh;
`;

export const Content = styled(Col)`
  text-align: center;
  margin: 50px 0;
`;

export const BgUNews = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  min-height: 30vh;
  padding: 20px;
  margin: 0 auto;
`;
