import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Container, Row, Col, Image } from 'react-bootstrap';

import { useCollection } from '~/hooks/useFirestore';

import { Description } from './styles';
import GlobalStyle from '~/styles/global';

import Session from '~/components/Session';
import Header from '~/components/Header';
import Footer from '~/components/Footer';

import AboutUsSkeleton from '~/skeletons/AboutUsSkeleton';

import staff from '~/assets/images/staff.svg';

export default function AboutUs() {
  const [loading, setLoading] = useState(false);
  const aboutus = useCollection('aboutus', setLoading);
  const [content, setContent] = useState('');

  useEffect(() => {
    setLoading(true);
    if (aboutus.length > 0) {
      setContent(aboutus[0].content);
      setLoading(false);
    }
  }, [aboutus]);

  return (
    <>
      <Header />
      <Session title="QUEM SOMOS" />
      <Container className="text-center">
        <Row>
          {loading && <AboutUsSkeleton />}
          {!loading && aboutus && (
            <Col md={6}>
              <Description>{parse(content)}</Description>
            </Col>
          )}
          <Col md={6} className="align-self-end">
            <Image
              src={staff}
              width={470}
              height={340}
              alt="Quem Somos"
              fluid
            />
          </Col>
        </Row>
      </Container>
      <Footer />
      <GlobalStyle />
    </>
  );
}
