import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export const Header = styled(Row)`
  border-bottom: 1px solid #ccc;
`;

export const Title = styled(Col)`
  background: #0d2344;
  border-top-left-radius: 10px;
  color: #f6f6f6;
  font-size: 18px;
  display: flex;
  flex-direction: row;
`;

export const Path = styled(Col)`
  font-size: 12px;
  font-weight: bold;
  color: #0d2344;
  display: flex;
  flex-direction: row;
  padding: 5px 0 0 10px;
`;

export const User = styled(Col)`
  display: flex;
  flex-direction: row-reverse;
  display: flex;

  a {
    color: #0d2344;
    text-decoration: none;
    font-size: 14px;
  }

  a:hover {
    color: #656b74;
  }
`;

export const Link = styled(NavLink)`
  padding-left: 5px;
`;

export const Logout = styled.button`
  padding-left: 5px;
  border: 0px;
  background: none;
  color: #0d2344;
  font-size: 14px;

  &:hover {
    color: #656b74;
  }
`;
