import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';
import { Background, Name } from './styles';

export default function Title(props) {
  const { title } = props;
  return (
    <>
      <Background>
        <Row>
          <Name md={4}>{title}</Name>
        </Row>
      </Background>
    </>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
};
