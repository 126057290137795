import React from 'react';
import { Col } from 'react-bootstrap';

import { LoadingSkeleton } from '~/skeletons/LoadingSkeleton';

import { Content, Title, Description, Card } from './styles';

export default function CardSkeleton() {
  return (
    <Col md={4}>
      <Content>
        <Card>
          <LoadingSkeleton borderRadius={10} />
        </Card>
        <Title>
          <LoadingSkeleton borderRadius={5} />
        </Title>
        <Description>
          <LoadingSkeleton />
        </Description>
        <Description>
          <LoadingSkeleton />
        </Description>
        <Description>
          <LoadingSkeleton />
        </Description>
      </Content>
    </Col>
  );
}
