import React from 'react';
import PropTypes from 'prop-types';

import { MdAddCircleOutline } from 'react-icons/md';
import { BtForm } from './styles';

export default function Button(props) {
  const { title } = props;

  return (
    <BtForm>
      <MdAddCircleOutline
        color="#f6f6f6"
        size={16}
        style={{ margin: '0 5px' }}
      />
      {title}
    </BtForm>
  );
}

Button.propTypes = {
  title: PropTypes.string.isRequired,
};
