import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';

import { useCollection } from '~/hooks/useFirestore';

import {
  AboutUs,
  Content,
  Title,
  Description,
  Depoiments,
  BgDepoiments,
  DepoimentsTitle,
  Author,
  Staff,
  Card,
  User,
  UserContent,
} from './styles';
import GlobalStyle from '~/styles/global';

import avatar from '~/assets/images/no-image-user.png';
import cardImage from '~/assets/images/no-image.png';

import Session from '~/components/Session';
import Header from '~./components/Header';
import Footer from '~/components/Footer';

import CardSkeleton from '~/skeletons/CardSkeleton';
import UserSkeleton from '~/skeletons/UserSkeleton';

export default function Home() {
  const [loadingCards, setLoadingCards] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);

  const cards = useCollection('cards', setLoadingCards);
  const users = useCollection('users', setLoadingUsers);
  const comments = useCollection('comments', setLoadingComments);

  const cardsSkeletons = [];
  const usersSkeletons = [];
  const commentsSkeletons = [];

  for (let i = 0; i < 3; i += 1) {
    cardsSkeletons.push(<CardSkeleton key={i} />);
  }

  for (let i = 0; i < 3; i += 1) {
    usersSkeletons.push(<UserSkeleton key={i} />);
  }

  for (let i = 0; i < 3; i += 1) {
    commentsSkeletons.push(<CardSkeleton key={i} />);
  }

  return (
    <>
      <Header />
      <Session title="SOBRE NÓS..." />
      <Container>
        <AboutUs>
          {loadingCards && cardsSkeletons}
          {!loadingCards &&
            cards &&
            cards.map((card) => (
              <Col md={4} key={card.id}>
                <Content>
                  <Card
                    width="250px"
                    height="164px"
                    src={card.imageUrl !== '' ? card.imageUrl : cardImage}
                    alt="Quem Somos"
                    fluid
                  />
                  <Title>
                    <p>{card.title}</p>
                  </Title>
                  <Description>
                    <p>{card.content}</p>
                  </Description>
                </Content>
              </Col>
            ))}
          {!loadingCards && !cards && <p>Nenhum card cadastrado.</p>}
        </AboutUs>
      </Container>
      <Session title="O QUE FALAM SOBRE NÓS..." />
      <Depoiments>
        <Container>
          <Row>
            {loadingComments && commentsSkeletons}
            {!loadingComments &&
              comments &&
              comments.map((comment) => (
                <Col md={4} key={comment.id}>
                  <BgDepoiments>
                    <DepoimentsTitle>
                      <p>{comment.title}</p>
                    </DepoimentsTitle>
                    <Description>
                      <p>{comment.comment}</p>
                    </Description>
                    <Author>{comment.author}</Author>
                  </BgDepoiments>
                </Col>
              ))}
            {!loadingComments && !comments && (
              <Col style={{ color: '#FFF' }}>Nenhum depoimento cadastrado.</Col>
            )}
          </Row>
        </Container>
      </Depoiments>
      <Session title="NOSSA EQUIPE" />
      <Staff>
        <Container>
          <Row>
            {loadingUsers && usersSkeletons}
            {!loadingUsers &&
              users &&
              users
                .filter((doc) => doc.type !== 'Webmaster')
                .map((user) => (
                  <Col md={4} key={user.id}>
                    <User
                      src={user.imageUrl !== '' ? user.imageUrl : avatar}
                      alt="Usuário"
                      fluid
                    />
                    <UserContent>
                      <h4>{user.name}</h4>
                      <hr />
                      <p>{user.specialization}</p>
                    </UserContent>
                  </Col>
                ))}
            {!loadingUsers && !cards && (
              <p style={{ margin: '0 auto' }}>Nenhum usuário cadastrado.</p>
            )}
          </Row>
        </Container>
      </Staff>
      <Footer />
      <GlobalStyle />
    </>
  );
}
