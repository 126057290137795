import styled from 'styled-components';

import { Container, Row, Image } from 'react-bootstrap';

export const AboutUs = styled(Row)`
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 40vh;
`;

export const Content = styled.div`
  width: 250px;
  display: inline-block;

  h4 {
    color: #0d2344;
  }

  p {
    margin-top: 5px;
    min-height: 60px;
  }
`;

export const Title = styled.div`
  width: 250px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 6px;

  p {
    text-align: center;
    font-size: 20px;
    margin-top: 7px;
  }
`;

export const Description = styled.div`
  width: 250px;
  margin-top: 10px;
  height: 120px;
  overflow: hidden;

  p {
    text-align: justify;
  }
`;

export const Card = styled(Image)`
  width: 250px;
  height: 164px;
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const Depoiments = styled(Container)`
  max-width: 100%;
  background-color: #0d2344;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: inline-flex;
  padding: 30px 0;
`;

export const BgDepoiments = styled.div`
  width: 270px;
  display: inline-block;
  background-color: #163056;
  color: #eee;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;

  p {
    margin-top: 5px;
    min-height: 60px;
  }
`;

export const DepoimentsTitle = styled.div`
  width: 240px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-top: 10px;

  p {
    text-align: center;
    font-size: 20px;
    margin-top: 7px;
  }
`;

export const Author = styled.p`
  font-weight: bold;
`;

export const Staff = styled(Container)`
  max-width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: inline-flex;
  padding: 30px 0;
  min-height: 50vh;
`;

export const User = styled(Image)`
  width: 250px;
  height: 350px;
  border: 1px solid #0d2344;
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const UserContent = styled.div`
  width: 250px;
  display: inline-block;
`;
