import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { Container, Col } from 'react-bootstrap';

import { useDoc } from '~/hooks/useFirestore';
import { formatDate } from '~/util/Utils';

import { News, Content, Author, Date } from './styles';
import GlobalStyle from '~/styles/global';

import Session from '~/components/Session';
import Header from '~/components/Header';
import Footer from '~/components/Footer';

import NewsDetailSkeleton from '~/skeletons/NewsDetailSkeleton';

export default function NewsDetail() {
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');
  const [createdAt, setCreatedAt] = useState('N/A');

  const { id } = useParams();
  const docs = useDoc('news', id);

  useEffect(() => {
    setLoading(true);
    if (docs.length > 0) {
      setTitle(docs[0].data().title);
      setAuthor(docs[0].data().author);
      setContent(docs[0].data().content);
      setCreatedAt(docs[0].data().createdAt);
      setLoading(false);
    }
  }, [docs]);

  return (
    <>
      <Header />
      <Session title={title} />
      <Container className="text-center" style={{ minHeight: '50vh' }}>
        {loading && <NewsDetailSkeleton />}

        {!loading && docs && (
          <>
            <Content>
              <Col md={12}>
                <Author>{author}</Author>
              </Col>
              <Col md={12}>
                <Date>{formatDate(createdAt)}</Date>
              </Col>
            </Content>
            <Content>
              <Col md={10}>
                <News>{parse(content)}</News>
              </Col>
            </Content>
          </>
        )}
      </Container>
      <Footer />
      <GlobalStyle />
    </>
  );
}
