import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import { Container } from 'react-bootstrap';

export const NewsContent = styled(Container)`
  min-height: 40vh;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: #0d2344;
  margin: 30px 0 5px 0;
  text-align: center;
`;

export const Content = styled.div`
  margin: 5px 10px 20px 10px;
  border: 1px solid #0d2344;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: justify;
  background: #f6f6f6;
  height: 20vh;
  overflow: hidden;

  span {
    font-size: 10px;
    color: #707070;
    font-weight: bold;
  }

  hr {
    margin-top: 0px;
  }

  p {
    text-align: justify;
    height: 65px;
    overflow: hidden;
  }
`;

export const More = styled(NavLink)`
  display: block;
  float: right;
`;
