import firebase from 'firebase/app';
import { db, credentials } from '../config/firebase';

const ref = db.collection('users');

export function createProfile(
  user,
  setError,
  setSuccess,
  setLoading,
  handleClear
) {
  try {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    return ref
      .add({
        uid: user.uid,
        name: user.name,
        email: user.email,
        type: user.type,
        imageUrl: '',
        specialization: '',
        enable: true,
        createdAt: new Date().toISOString(),
        updatedAt: '',
        timestamp,
      })
      .then(() => {
        setLoading(false);
        handleClear();
        return setSuccess('Usuário criado com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(
          `Ocorreu um erro ao criar o perfil do usuário. ${err.code}`
        );
      });
  } catch (err) {
    return setError(
      `Ocorreu um erro ao criar o perfil do usuário. ${err.code}`
    );
  }
}

export function updateProfile(
  user,
  setError,
  setSuccess,
  setLoading,
  setUpload
) {
  const newRef = db.collection('users').doc(user.docId);
  try {
    return newRef
      .update({
        name: user.name,
        email: user.email,
        type: user.type,
        specialization: user.specialization,
        updatedAt: new Date().toISOString(),
      })
      .then(() => {
        setUpload(true);
        setLoading(false);
        return setSuccess('Perfil atualizado com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar o perfil ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao atualizar o perfil ${err}`);
  }
}

export function disableUser(doc, setError, setSuccess) {
  const newRef = db.collection(doc.ref).doc(doc.id);
  try {
    return newRef
      .update({
        enable: false,
      })
      .then(() => {
        return setSuccess('Usuário removido com sucesso.');
      })
      .catch((err) => {
        return setError(`Ocorreu um erro ao remover o usuário ${err}`);
      });
  } catch (err) {
    return setError(`Ocorreu um erro ao remover o usuario ${err}`);
  }
}

const createNewConnection = () => {
  let secondaryConnection;
  try {
    secondaryConnection = firebase.initializeApp(credentials, 'authentication');
  } catch {
    secondaryConnection = firebase.app('authentication');
  }
  return secondaryConnection;
};

export function createUser(
  user,
  setError,
  setSuccess,
  setLoading,
  handleClear
) {
  try {
    const secondaryConnection = createNewConnection();
    return secondaryConnection
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((res) => {
        const userWithUid = { uid: res.user.uid, ...user };
        return createProfile(
          userWithUid,
          setError,
          setSuccess,
          setLoading,
          handleClear
        );
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao criar o usuário. ${err.code}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao criar o usuário. ${err.code}`);
  }
}
