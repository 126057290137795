import React, { useState } from 'react';
import { BiNews } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import { Content, Title, More, NewsContent } from './styles';
import GlobalStyle from '../../styles/global';

import { formatDate } from '~/util/Utils';
import { useCollection } from '~/hooks/useFirestore';

import Session from '~/components/Session';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import Pagination from '~/components/Pagination';

import NewsSkeleton from '~/skeletons/NewsSkeleton';

export default function News() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage, setNewsPerPage] = useState(10);
  const news = useCollection('news', setLoading);

  const indexOfLastPost = currentPage * newsPerPage;
  const indexOfFirstPost = indexOfLastPost - newsPerPage;
  const currentPosts = news.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const newsSkeletons = [];

  for (let i = 0; i < 4; i += 1) {
    newsSkeletons.push(<NewsSkeleton key={i} />);
  }

  return (
    <>
      <Header />
      <Session title="NOVIDADES" />
      <NewsContent className="text-center">
        <Row>
          {loading && newsSkeletons}
          {!loading &&
            news &&
            currentPosts.map((content) => (
              <Col md={6} key={content.id}>
                <NavLink
                  to={`/noticia/${content.id}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Title>
                    <BiNews size={18} color="#0d2344" /> {content.title}
                  </Title>
                </NavLink>
                <Content>
                  <span>
                    {content.author} - {formatDate(content.createdAt)}
                  </span>
                  <hr />
                  <p>{content.resume}</p>
                  <More to={`/noticia/${content.id}`}>Leia mais...</More>
                </Content>
              </Col>
            ))}
          <Col md={12}>
            <Pagination
              postsPerPage={newsPerPage}
              totalPosts={news.length}
              paginate={paginate}
            />
          </Col>
          {!loading && !news && (
            <p style={{ margin: '0 auto' }}>Nenhuma notícia cadastrada</p>
          )}
        </Row>
      </NewsContent>
      <Footer />
      <GlobalStyle />
    </>
  );
}
