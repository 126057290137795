import React, { useEffect, useState } from 'react';

import {
  MdEditLocation,
  MdCall,
  MdPhoneAndroid,
  MdLocationCity,
  MdEmail,
} from 'react-icons/md';

import { Image } from 'react-bootstrap';
import { useCollection } from '~/hooks/useFirestore';

import FooterContactsSkeleton from '~/skeletons/FooterContactsSkeleton';

import { Background, Line, Column } from './styles';
import GlobalStyle from '~/styles/global';

import map from '~/assets/images/mapa.png';

export default function Footer() {
  const [loading, setLoading] = useState(false);
  const [street, setStreet] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [phoneOne, setPhoneOne] = useState('');
  const [phoneTwo, setPhoneTwo] = useState('');

  const contacts = useCollection('contacts', setLoading);

  useEffect(() => {
    setLoading(true);
    if (contacts.length > 0) {
      setStreet(contacts[0].street);
      setNeighborhood(contacts[0].neighborhood);
      setNumber(contacts[0].number);
      setCity(contacts[0].city);
      setState(contacts[0].state);
      setEmail(contacts[0].email);
      setPhoneOne(contacts[0].phoneOne);
      setPhoneTwo(contacts[0].phoneTwo);
      setLoading(false);
    }
  }, [contacts]);

  return (
    <>
      <Background>
        <Line>
          {loading && <FooterContactsSkeleton />}
          {!loading && contacts && (
            <Column md={4}>
              <h1>ENTRE EM CONTATO</h1>
              <hr />
              <p>
                <MdEditLocation color="#EEE" size={16} /> {street} {number},{' '}
                {neighborhood}
              </p>
              <p>
                <MdLocationCity color="#EEE" size={16} /> {city} - {state}
              </p>
              <p>
                <MdCall color="#EEE" size={16} /> Fone: +55 {phoneOne}
              </p>
              {phoneTwo !== '' && (
                <p>
                  <MdPhoneAndroid color="#EEE" size={16} /> Fone: +55 {phoneTwo}
                </p>
              )}
              <p>
                <MdEmail color="#EEE" size={16} /> E-mail: {email}
              </p>
            </Column>
          )}
          <Column md={4}>
            {/* <Image src={map} width={300} height={250} alt="Quem Somos" fluid /> */}
            <iframe
              title="Morais e Travassos"
              width="350"
              height="350"
              frameBorder="0"
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJO6dYIgvorAcRq9PNJ7IWBG8&key=AIzaSyALTeJEx83b1CEW5v_alRFg9CVJSP-XBik"
              allowFullScreen
            />
          </Column>
          <Column md={6}>
            <p style={{ textAlign: 'center' }}>
              Copyright © 2020 - Morais e Travassos Advogados - Todos os
              Direitos Reservados
            </p>
          </Column>
        </Line>
      </Background>
      <GlobalStyle />
    </>
  );
}
