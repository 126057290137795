import { formatRelative, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';

export function resetMessages(setError, setSuccess) {
  setError('');
  setSuccess('');
}

export function formatDate(date) {
  if (date !== '' && date !== undefined && date !== 'N/A') {
    return formatRelative(parseISO(date), new Date(), { locale: pt });
  }
  return 'N/A';
}

export function validateImage(file, setFile, setError) {
  const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
  if (file && types.includes(file.type)) {
    return setFile(file);
  }
  setFile(null);
  return setError('Selecione uma imagem do tipo (png, jpeg, jpg ou svg)');
}
