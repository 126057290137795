import styled from 'styled-components';

export const BtForm = styled.button`
  background: #0d2344;
  color: #f6f6f6;
  width: 180px;
  padding: 5px;
  border-radius: 10px;
  margin: 0 10px 10px 0;
  float: right;
  font-size: 12px;
`;
