import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const Background = styled(Container)`
  text-align: left;
  width: 100%;
  padding: 0 30px;
`;

export const Name = styled.h1`
  color: #0d2344;
  font-size: 14px;
  font-weight: bold;
  padding-top: 5px;
  float: left;
`;
