import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Table, Row, Col } from 'react-bootstrap';
import { MdEdit, MdDelete } from 'react-icons/md';

import { formatDate } from '~/util/Utils';
import { useCollection } from '~/hooks/useFirestore';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import Button from '~/components/Button';
import RemoveConfirmation from '~/components/RemoveConfirmation';
import Pagination from '~/components/Pagination';

import { Background, Body, Content, BgUNews } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function Dashboard() {
  const [skeleton, setSkeleton] = useState(false);
  const collection = 'comments';
  const docs = useCollection(collection, setSkeleton);
  const [removeId, setRemoveId] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [docsPerPage, setdocsPerPage] = useState(10);

  const indexOfLastPost = currentPage * docsPerPage;
  const indexOfFirstPost = indexOfLastPost - docsPerPage;
  const currentPosts = docs.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRemove = (id) => {
    setConfirmation(true);
    setRemoveId(id);
  };

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            {confirmation && (
              <RemoveConfirmation
                setConfirmation={setConfirmation}
                removeId={removeId}
                refName={collection}
                currentUser={null}
              />
            )}

            <Row>
              <Col md={6}>
                <Title title="GERECIAMENTO DE DEPOIMENTOS" />
              </Col>
              <Col md={6}>
                <NavLink to="/admin/depoimentos/add">
                  <Button title="CADASTRAR" />
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <BgUNews>
                  {docs.length > 0 ? (
                    <>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Título</th>
                            <th>Autor</th>
                            <th>Criado</th>
                            <th>Modificado</th>
                            <th>Opções</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPosts.map((comment) => (
                            <tr key={comment.id}>
                              <td>{comment.title}</td>
                              <td>{comment.author}</td>
                              <td>{formatDate(comment.createdAt)}</td>
                              <td>{formatDate(comment.updateAt)}</td>
                              <td>
                                <Link
                                  to={`/admin/depoimentos/upd/${comment.id}`}
                                >
                                  <MdEdit size={18} color="#000" />{' '}
                                </Link>
                                <MdDelete
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleRemove(comment.id)}
                                  size={18}
                                  color="#000"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Pagination
                        postsPerPage={docsPerPage}
                        totalPosts={docs.length}
                        paginate={paginate}
                      />
                    </>
                  ) : (
                    <p>Nenhuma depoimento cadastrado.</p>
                  )}
                </BgUNews>
              </Col>
            </Row>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
