import styled from 'styled-components';
import { Container, Col } from 'react-bootstrap';

export const Background = styled(Container)`
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 80%;
  padding: 15px 20px;
`;

export const Line = styled(Col)`
  margin: 20px 0;
  border: 1px solid #0d2344;
  height: 1px;
`;

export const Title = styled(Col)`
  color: #0d2344;
  font-size: 28px;
`;
