import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const SkeletonPulse = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  border-radius: ${(props) => `${props.borderRadius}px` || '0px'};
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SkeletonBar = styled(SkeletonPulse)`
  width: 5.5em;
  /* border-radius: 5px; */
  &::before {
    content: '\00a0';
  }
`;

export const Content = styled.div`
  width: 250px;
  display: inline-block;
`;

export const Title = styled.div`
  width: 250px;
  height: 40px;
`;

export const Description = styled.div`
  width: 250px;
  margin-top: 10px;
  height: 10px;
`;

export const Card = styled.div`
  width: 250px;
  height: 164px;
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const User = styled.div`
  width: 250px;
  height: 350px;
  border-radius: 10px;
  margin-bottom: 5px;
`;

export const NewsTitle = styled.div`
  width: 250px;
  height: 30px;
  margin: 30px 0 5px 5px;
`;

export const NewsContent = styled.div`
  width: 450px;
  height: 15vh;
  margin: 5px 10px 20px 10px;
`;

export const Author = styled.div`
  width: 150px;
  height: 20px;
  margin-bottom: 10px;
`;

export const Date = styled.div`
  width: 150px;
  height: 20px;
`;

export const News = styled.div`
  width: 800px;
  height: 10px;
`;

export const NewsDetailContent = styled(Row)`
  display: grid;
  justify-content: center;
  margin-top: 10px;
`;

export const AboutUs = styled.div`
  width: 400px;
  height: 10px;
  margin-bottom: 5px;
`;

export const FooterTitle = styled.div`
  width: 300px;
  height: 20px;
  margin-bottom: 10px;
  margin-top: 80px;
`;

export const FooterContent = styled.div`
  width: 300px;
  height: 10px;
  margin-top: 15px;
`;

export const HeaderContacts = styled.div`
  width: 200px;
  height: 1.22em;
  margin: 0 auto;
`;
