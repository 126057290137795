import React, { useState } from 'react';
import { Form, Col, Row, Alert } from 'react-bootstrap';

import { resetMessages } from '~/util/Utils';
import { useAuth } from '~/contexts/AuthContext';
import { createUser } from '~/controllers/UserController';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import SubmitButton from '~/components/SubmitButton';

import { Background, Body, Content, BgForm } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function InputNews() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const title = 'Cadastrar Usuário';

  const handleClear = () => {
    setName('');
    setEmail('');
    setType('');
    setPassword('');
    setPasswordConfirm('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    resetMessages(setError, setSuccess);

    if (password !== passwordConfirm) {
      setLoading(false);
      return setError('As senhas precisam ser iguais.');
    }

    const user = { name, email, password, type };
    return createUser(user, setError, setSuccess, setLoading, handleClear);
  };

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            <Title title="CADASTRAR NOVO USUÁRIO" />
            <BgForm>
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="formHorizontalName">
                  <Form.Label column sm={4}>
                    Nome:
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Informe o nome"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column sm={4}>
                    Email:
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Informe o e-mail"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalTipo">
                  <Form.Label column sm={4}>
                    Tipo:
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as="select"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">Selecione uma opção</option>
                      <option value="Administrador">Administrador</option>
                      <option value="Normal">Normal</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalPassword">
                  <Form.Label column sm={4}>
                    Senha:
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="password"
                      value={password}
                      placeholder="Informe a senha"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalConfirmPassword">
                  <Form.Label column sm={4}>
                    Confirmar Senha:
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="password"
                      value={passwordConfirm}
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      placeholder="Confirme a senha"
                      required
                    />
                  </Col>
                </Form.Group>
                <SubmitButton title={title} loading={loading.toString()} />
              </Form>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert className="mt-3" variant="success">
                  {success}
                </Alert>
              )}
            </BgForm>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
