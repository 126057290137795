import { db } from '../config/firebase';

export const removeDocuments = (doc, setError, setSuccess) => {
  const newRef = db.collection(doc.ref).doc(doc.id);
  try {
    return newRef
      .delete()
      .then(() => {
        return setSuccess('Registro removido com sucesso.');
      })
      .catch((err) => {
        return setError(`Ocorreu um erro ao remover o registro ${err}`);
      });
  } catch (err) {
    return setError(`Ocorreu um erro ao remover o registro ${err}`);
  }
};

export const removeUser = (currentUser, setError) => {
  try {
    return currentUser.delete().catch((err) => {
      return setError(`Ocorreu um erro ao remover o usuário. ${err}`);
    });
  } catch (err) {
    return setError(`Ocorreu um erro ao remover o usuário. ${err}`);
  }
};

export default { removeDocuments, removeUser };
