import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useStorage from '~/hooks/useStorage';

import { Bar } from './styles';

const ProgressBar = ({ file, docId, setFile, refName }) => {
  const { url, progress } = useStorage(file, docId, refName);

  useEffect(() => {
    if (url) {
      setFile(null);
    }
  }, [url, setFile]);

  return <Bar style={{ widht: `${progress}%` }} />;
};

export default ProgressBar;

ProgressBar.propTypes = {
  file: PropTypes.instanceOf(Object),
  setFile: PropTypes.func,
  docId: PropTypes.string,
  refName: PropTypes.string,
};

ProgressBar.defaultProps = {
  file: PropTypes.instanceOf(Object),
  setFile: PropTypes.func,
  docId: PropTypes.string,
  refName: PropTypes.string,
};
