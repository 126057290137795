import firebase from 'firebase/app';
import { db } from '../config/firebase';

const ref = db.collection('news');

export function createNews(
  content,
  setError,
  setSuccess,
  setLoading,
  handleClear
) {
  try {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    return ref
      .add({
        author: content.author,
        uid: content.uid,
        title: content.title,
        resume: content.resume,
        content: content.content,
        enable: true,
        createdAt: new Date().toISOString(),
        updatedAt: '',
        timestamp,
      })
      .then(() => {
        setLoading(false);
        handleClear();
        return setSuccess('Notícia cadastrada com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao cadastrar a notícia. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao cadastrar a notícia. ${err}`);
  }
}

export function updateNews(content, setError, setSuccess, setLoading) {
  const newRef = db.collection('news').doc(content.newsId);
  try {
    return newRef
      .update({
        title: content.title,
        resume: content.resume,
        content: content.content,
        updatedAt: new Date().toISOString(),
      })
      .then(() => {
        setLoading(false);
        return setSuccess('Notícia atualizada com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar a notícia. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao atualizar a notícia. ${err}`);
  }
}
