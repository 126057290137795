import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Dropdown, DropdownButton, Alert } from 'react-bootstrap';
import { Header, Title, Path, User, Link, Logout } from './styles';

import { useAuth } from '~/contexts/AuthContext';

export default function NavBar() {
  const { LogOut, currentUser } = useAuth();
  const [error, setError] = useState('');
  const history = useHistory();

  async function handleLogout() {
    setError('');

    try {
      await LogOut();
      history.push('/login');
    } catch {
      setError('Failed to log out');
    }
  }

  return (
    <Header>
      {error && <Alert variant="danger">{error}</Alert>}

      <Title md={2}>Menu</Title>
      <Path md={4}>{window.location.pathname}</Path>
      <User md={6}>
        <Dropdown>
          <DropdownButton
            size="sm"
            variant="inline-secondary"
            title={currentUser.email}
          >
            <Link to="/admin/perfil">Editar informações</Link>
            <Dropdown.Divider />
            <Logout onClick={handleLogout}>Sair do Sistema</Logout>
          </DropdownButton>
        </Dropdown>
      </User>
    </Header>
  );
}
