import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Image, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { db } from '~/config/firebase';

import SubmitButton from '~/components/SubmitButton';
import { useAuth } from '~/contexts/AuthContext';

import { Background } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

import { useQuery } from '~/hooks/useFirestore';

import logo from '~/assets/images/logo.png';

export default function SignIn() {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { LogIn } = useAuth();
  const history = useHistory();

  const title = 'Acessar Sistema';

  async function handleLogin() {
    try {
      setError('');
      setLoading(true);
      await LogIn(emailRef.current.value, passwordRef.current.value);
      history.push('/admin');
    } catch {
      setLoading(false);
      return setError('Usuário ou senha inválidos.');
    }
    setLoading(false);
    return false;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    db.collection('users')
      .where('email', '==', emailRef.current.value)
      .onSnapshot((snap) => {
        const documents = [];
        snap.forEach((doc) => {
          if (!doc.data().enable) {
            return setError('Usuário desativado ou não existe.');
          }
          return handleLogin();
        });
      });
    return setError('Usuário não cadastrado');
  }

  return (
    <>
      <Container className="text-center p-xl-5 mt-xl-5">
        <Row>
          <Col>
            <Image
              src={logo}
              width={500}
              height={250}
              alt="Morais & Travassos"
              fluid
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Background>
              <h1>Área Administrativa</h1>
              <hr />
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    ref={emailRef}
                    placeholder="Digite seu e-mail"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    ref={passwordRef}
                    placeholder="Digite sua senha"
                    required
                  />
                </Form.Group>
                <SubmitButton title={title} loading={loading.toString()} />
              </Form>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}
            </Background>
          </Col>
        </Row>
      </Container>
      <GlobalStyle />
    </>
  );
}
