import firebase from 'firebase/app';
import { db } from '../config/firebase';

const ref = db.collection('cards');

export function createCard(
  content,
  setError,
  setSuccess,
  setLoading,
  setDocId,
  handleClear
) {
  try {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    return ref
      .add({
        content: content.content,
        title: content.title,
        enable: true,
        imageUrl: '',
        createdAt: new Date().toISOString(),
        updatedAt: '',
        timestamp,
      })
      .then((doc) => {
        setDocId(doc.id);
        setLoading(false);
        handleClear();
        return setSuccess('Card cadastrado com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar o card ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao atualizar o card ${err}`);
  }
}

export function updateCard(
  content,
  setError,
  setSuccess,
  setLoading,
  setDocId
) {
  const newRef = db.collection('cards').doc(content.id);
  try {
    return newRef
      .update({
        content: content.content,
        title: content.title,
        updatedAt: new Date().toISOString(),
      })
      .then(() => {
        setDocId(content.id);
        setLoading(false);
        return setSuccess('Card atualizado com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar o card ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao atualizar o card ${err}`);
  }
}
