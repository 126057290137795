import firebase from 'firebase/app';
import { db } from '../config/firebase';

const ref = db.collection('aboutus');

export function createAboutUs(content, setError, setSuccess, setLoading) {
  try {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    return ref
      .add({
        content,
        enable: true,
        createdAt: new Date().toISOString(),
        updatedAt: '',
        timestamp,
      })
      .then(() => {
        setLoading(false);
        return setSuccess('Informações salvas com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar as informações. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao cadastrar as informações. ${err}`);
  }
}

export function updateAboutUs(content, setError, setSuccess, setLoading) {
  const newRef = db.collection('aboutus').doc(content.id);
  try {
    return newRef
      .update({
        content: content.content,
        updatedAt: new Date().toISOString(),
      })
      .then(() => {
        setLoading(false);
        return setSuccess('Informações atualizadas com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar as informações. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao atualizar as informações. ${err}`);
  }
}
