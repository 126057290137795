import styled from 'styled-components';

export const Number = styled.li`
  margin: 30px 2px 0 2px;
`;

export const NavBar = styled.nav`
  margin: 0 auto;
`;

export const Button = styled.button`
  color: #0d2344;
`;
