import React from 'react';
import { Col } from 'react-bootstrap';

import { LoadingSkeleton } from '~/skeletons/LoadingSkeleton';

import { NewsDetailContent, Author, Date, News } from './styles';

export default function CardSkeleton() {
  const details = [];
  for (let i = 0; i < 15; i += 1) {
    details.push(<LoadingSkeleton key={i} />);
  }
  return (
    <>
      <NewsDetailContent>
        <Col md={12}>
          <Author>
            <LoadingSkeleton />
          </Author>
        </Col>
        <Col md={12}>
          <Date>
            <LoadingSkeleton />
          </Date>
        </Col>
      </NewsDetailContent>
      <NewsDetailContent>
        <Col md={10}>
          <News>{details}</News>
        </Col>
      </NewsDetailContent>
    </>
  );
}
