import React from 'react';
import { Col } from 'react-bootstrap';

import { LoadingSkeleton } from '~/skeletons/LoadingSkeleton';

import { NewsTitle, NewsContent } from './styles';

export default function CardSkeleton() {
  return (
    <Col md={6}>
      <NewsTitle>
        <LoadingSkeleton borderRadius={10} />
      </NewsTitle>
      <NewsContent>
        <LoadingSkeleton borderRadius={10} />
      </NewsContent>
    </Col>
  );
}
