import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const Menu = styled(Col)`
  background: #0d2344;
  font-size: 14px;
  padding: 10px;
  border-bottom-left-radius: 10px;

  a {
    color: #f6f6f6;
    text-decoration: none;
    margin-bottom: 10px;
  }

  a:hover {
    color: #ccc;
  }
`;
