import { useState, useEffect } from 'react';
import { storage, db } from '~/config/firebase';

const useStorage = (file, docId, ref) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('');
  const [url, setUrl] = useState('');

  const uniqueFilename = (originalFilename) => {
    const fileExtension = originalFilename.split('.')[
      originalFilename.split('.').length - 1
    ];
    const newFilename = `${Math.round(
      Math.random() * 10000000000000
    )}.${fileExtension}`;
    return newFilename;
  };

  useEffect(() => {
    const newFilename = uniqueFilename(file.name);
    const storageRef = storage.ref(ref).child(newFilename);
    const collectionRef = db.collection(ref).doc(docId);
    storageRef.put(file).on(
      'state_change',
      (snap) => {
        const percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      async () => {
        const urlStorage = await storageRef.getDownloadURL();
        collectionRef.update({
          imageUrl: urlStorage,
          updatedAt: new Date().toISOString(),
        });
        setUrl(urlStorage);
      }
    );
  }, [file, docId, ref]);

  return { progress, url, error };
};

export default useStorage;
