import firebase from 'firebase/app';
import { db } from '../config/firebase';

const ref = db.collection('contacts');

export function createContacts(content, setError, setSuccess, setLoading) {
  try {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    return ref
      .add({
        street: content.street,
        neighborhood: content.neighborhood,
        number: content.number,
        city: content.city,
        state: content.state,
        email: content.email,
        phoneOne: content.phoneOne,
        phoneTwo: content.phoneTwo,
        enable: true,
        createdAt: new Date().toISOString(),
        updatedAt: '',
        timestamp,
      })
      .then(() => {
        setLoading(false);
        return setSuccess('Contatos cadastrados com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao cadastrar os contatos. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao cadastrar os contatos. ${err}`);
  }
}

export function updateContacts(content, setError, setSuccess, setLoading) {
  const newRef = db.collection('contacts').doc(content.id);
  try {
    return newRef
      .update({
        street: content.street,
        neighborhood: content.neighborhood,
        number: content.number,
        city: content.city,
        state: content.state,
        email: content.email,
        phoneOne: content.phoneOne,
        phoneTwo: content.phoneTwo,
        updatedAt: new Date().toISOString(),
      })
      .then(() => {
        setLoading(false);
        return setSuccess('Contatos atualizados com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar os contatos. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao atualizar os contatos. ${err}`);
  }
}
