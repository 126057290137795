import React from 'react';

import { Image } from 'react-bootstrap';
import Navbar from '../../../components/Navbar';
import Sidebar from '../../../components/Sidebar';

import { Background, Body, Content } from './styles';
import GlobalStyle from '../../../styles/globalAdmin';

import logo from '../../../assets/images/logo.png';

export default function Dashboard() {
  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            <Image
              src={logo}
              width={450}
              height={250}
              alt="Morais & Travassos"
              fluid
            />
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
