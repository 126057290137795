import React, { useEffect, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { resetMessages } from '~/util/Utils';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import SubmitButton from '~/components/SubmitButton';

import { createNews, updateNews } from '~/controllers/NewsController';
import { useAuth } from '~/contexts/AuthContext';
import { useDoc, useQuery } from '~/hooks/useFirestore';

import { Background, Body, Content, BgForm } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function InputNews() {
  const [title, setTitle] = useState('');
  const [resume, setResume] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('N/A');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { currentUser } = useAuth();
  const { newsId } = useParams();

  const docs = useDoc('news', newsId);
  const user = useQuery('users', 'uid', currentUser.uid);

  const btTitle = 'Cadastrar Notícia';

  const handleClear = () => {
    setTitle('');
    setResume('');
    setContent('');
    setAuthor('N/A');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    resetMessages(setError, setSuccess);
    setLoading(true);

    const contentObj = {
      title,
      resume,
      content,
      author,
      uid: currentUser.uid,
    };
    if (isUpdate) {
      return updateNews(
        { newsId, ...contentObj },
        setError,
        setSuccess,
        setLoading
      );
    }
    return createNews(
      contentObj,
      setError,
      setSuccess,
      setLoading,
      handleClear
    );
  };

  useEffect(() => {
    if (docs.length > 0) {
      setIsUpdate(true);
      setTitle(docs[0].data().title);
      setResume(docs[0].data().resume);
      setContent(docs[0].data().content);
    }
    if (user.length > 0) {
      setAuthor(user[0].name);
    }
  }, [docs, newsId, user]);

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            <Title title="CADASTRAR NOTÍCIA" />
            <BgForm>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicTitle">
                  <Form.Label>Titulo da Notícia: </Form.Label>
                  <Form.Control
                    type="Text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Informe seu nome"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicResume">
                  <Form.Label>Resumo da Notícia: </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={resume}
                    onChange={(e) => setResume(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlContent">
                  <Form.Label>Notícia Completa: </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      setContent(editor.getData());
                    }}
                    data={content}
                  />
                </Form.Group>

                <SubmitButton title={btTitle} loading={loading.toString()} />
              </Form>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert className="mt-3" variant="success">
                  {success}
                </Alert>
              )}
            </BgForm>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
