import styled, { keyframes, css } from 'styled-components';
import { Button } from 'react-bootstrap';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360g);
  }
`;

export const SubmitButton = styled(Button).attrs((props) => ({
  type: 'submit',
  disabled: props.loading === 'true',
}))`
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${(props) =>
    props.loading === 'true' &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`;
