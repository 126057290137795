import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';
import { Background, Line, Title } from './styles';
import GlobalStyle from '../../styles/global';

export default function Session(props) {
  const { title } = props;
  return (
    <>
      <Background>
        <Row>
          <Line md={4} />
          <Title md={4}>{title}</Title>
          <Line md={4} />
        </Row>
      </Background>
      <GlobalStyle />
    </>
  );
}

Session.propTypes = {
  title: PropTypes.string.isRequired,
};
