import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const News = styled.div`
  text-align: justify;
  background: #f6f6f6;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
`;

export const Content = styled(Row)`
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
`;

export const Author = styled.p`
  color: #707070;
  font-weight: bold;
`;

export const Date = styled.p`
  color: #707070;
`;
