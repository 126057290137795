import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Alert, Button } from 'react-bootstrap';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import ProgressBar from '~/components/ProgressBar';
import SubmitButton from '~/components/SubmitButton';
import RemoveConfirmation from '~/components/RemoveConfirmation';

import { resetMessages, validateImage } from '~/util/Utils';
import { updateProfile } from '~/controllers/UserController';
import { useQuery } from '~/hooks/useFirestore';
import { useAuth } from '~/contexts/AuthContext';

import { Background, Body, Content, BgForm, Avatar } from './styles';
import GlobalStyle from '~/styles/globalAdmin';
import avatar from '~/assets/images/no-image-user.png';

export default function Profile() {
  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [email, setEmail] = useState('');
  const [emailOld, setEmailOld] = useState('');
  const [type, setType] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [passwordOld, setPasswordOld] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [file, setFile] = useState(null);

  const [docId, setDocId] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const { currentUser, updateEmail, updatePassword } = useAuth();
  const refName = 'users';
  const doc = useQuery(refName, 'uid', currentUser.uid);
  const btTitle = 'Atualizar Informações';

  const hasValidPassword = () => {
    if (
      (passwordOld === '' && email !== emailOld) ||
      (passwordOld === '' && password !== '')
    ) {
      setLoading(false);
      setError(
        'Necessário informar a senha antiga para atualizar a senha atual e o e-mail.'
      );
      return false;
    }

    if (password !== '' && password !== passwordConfirm) {
      setLoading(false);
      setError('As senhas precisam ser iguais.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!hasValidPassword) {
      return false;
    }

    const data = {
      docId,
      name,
      email,
      password,
      type,
      specialization,
      emailOld,
      passwordOld,
    };

    if (email !== emailOld) {
      updateEmail(currentUser, data, setError);
    }

    if (password !== '') {
      updatePassword(currentUser, data, setError);
    }

    return updateProfile(data, setError, setSuccess, setLoading, setUpload);
  };

  const handleImage = (e) => {
    const selected = e.target.files[0];
    resetMessages(setError, setSuccess);
    return validateImage(selected, setFile, setError);
  };

  const handleRemove = () => {
    if (passwordOld === '') {
      return setError('Você deve informar sua senha atual');
    }
    return setConfirmation(true);
  };

  useEffect(() => {
    if (doc.length > 0) {
      setImageUrl(doc[0].imageUrl);
      setName(doc[0].name);
      setEmail(doc[0].email);
      setEmailOld(currentUser.email);
      setType(doc[0].type);
      setSpecialization(doc[0].specialization);
      setPassword('');
      setPasswordConfirm('');
      setDocId(doc[0].id);
    }
  }, [doc, currentUser]);

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            {confirmation && (
              <RemoveConfirmation
                setConfirmation={setConfirmation}
                removeId={docId}
                password={passwordOld}
                refName={refName}
                currentUser={currentUser}
              />
            )}
            {doc.length > 0 ? (
              <>
                <Title title="EDITAR INFORMAÇÕES" />
                <BgForm>
                  <div style={{ textAlign: 'center' }}>
                    <Avatar
                      src={imageUrl !== '' ? imageUrl : avatar}
                      alt="Morais & Travassos"
                      fluid
                    />
                  </div>
                  <hr />
                  <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="formHorizontalName">
                      <Form.Label column sm={4}>
                        Nome:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Informe o nome"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      <Form.Label column sm={4}>
                        Email:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="email"
                          placeholder="Informe o e-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formHorizontalTipo">
                      <Form.Label column sm={4}>
                        Tipo:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="select"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="">Selecione uma opção</option>
                          <option value="Administrador">Administrador</option>
                          <option value="Normal">Normal</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="formHorizontalSpecialization"
                    >
                      <Form.Label column sm={4}>
                        Especialidade:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={specialization}
                          onChange={(e) => setSpecialization(e.target.value)}
                          placeholder="Informe a especialidade"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formHorizontalAvatar">
                      <Form.Label column sm={4}>
                        Avatar:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control type="file" onChange={handleImage} />
                      </Col>
                    </Form.Group>
                    {upload && file && (
                      <ProgressBar
                        file={file}
                        docId={docId}
                        setFile={setFile}
                        refName={refName}
                      />
                    )}

                    <hr />

                    <Form.Group as={Row} controlId="formHorizontalOldPassword">
                      <Form.Label column sm={4}>
                        Senha Atual:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          value={passwordOld}
                          onChange={(e) => setPasswordOld(e.target.value)}
                          placeholder="Informe a nova senha"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formHorizontalPassword">
                      <Form.Label column sm={4}>
                        Nova Senha:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Informe a nova senha"
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="formHorizontalConfirmPassword"
                    >
                      <Form.Label column sm={4}>
                        Confirmar Senha:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="password"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          placeholder="Confirme a nova senha"
                        />
                      </Col>
                    </Form.Group>
                    <div style={{ textAlign: 'center' }}>
                      <SubmitButton
                        title={btTitle}
                        loading={loading.toString()}
                      />
                      <br />
                      <Button
                        type="button"
                        style={{ marginTop: '10px' }}
                        variant="danger"
                        onClick={() => handleRemove()}
                      >
                        Remover Conta
                      </Button>
                    </div>
                  </Form>
                  {error && (
                    <Alert className="mt-3" variant="danger">
                      {error}
                    </Alert>
                  )}
                  {success && (
                    <Alert className="mt-3" variant="success">
                      {success}
                    </Alert>
                  )}
                </BgForm>
              </>
            ) : (
              <h5>Nenhum perfil cadastrado para esse usuário.</h5>
            )}
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
