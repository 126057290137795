import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Alert } from 'react-bootstrap';

import { resetMessages } from '~/util/Utils';
import {
  createContacts,
  updateContacts,
} from '~/controllers/ContactController';
import { useCollection } from '~/hooks/useFirestore';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import SubmitButton from '~/components/SubmitButton';

import { Background, Body, Content, BgForm } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function InputNews() {
  const [skeleton, setSkeleton] = useState(false);

  const [id, setId] = useState('');
  const [street, setStreet] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [email, setEmail] = useState('');
  const [phoneOne, setPhoneOne] = useState('');
  const [phoneTwo, setPhoneTwo] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const title = 'Cadastrar Contatos';
  const contacts = useCollection('contacts', setSkeleton);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    resetMessages(setError, setSuccess);

    const data = {
      street,
      neighborhood,
      number,
      city,
      state,
      email,
      phoneOne,
      phoneTwo,
    };

    if (isUpdate) {
      return updateContacts({ id, ...data }, setError, setSuccess, setLoading);
    }
    return createContacts(data, setError, setSuccess, setLoading);
  };

  useEffect(() => {
    if (contacts.length > 0) {
      setId(contacts[0].id);
      setStreet(contacts[0].street);
      setNeighborhood(contacts[0].neighborhood);
      setNumber(contacts[0].number);
      setCity(contacts[0].city);
      setState(contacts[0].state);
      setEmail(contacts[0].email);
      setPhoneOne(contacts[0].phoneOne);
      setPhoneTwo(contacts[0].phoneTwo);
      setIsUpdate(true);
    }
  }, [contacts]);

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            <Title title="CADASTRAR CONTATOS" />
            <BgForm>
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} controlId="formHorizontalStreet">
                  <Form.Label column sm={2}>
                    Rua:
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                      placeholder="Informe o nome da rua"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="formHorizontalNeighborhoodNumber"
                >
                  <Form.Label column sm={2}>
                    Bairro:
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      value={neighborhood}
                      onChange={(e) => setNeighborhood(e.target.value)}
                      placeholder="Informe o nome do bairro"
                      required
                    />
                  </Col>
                  <Form.Label column sm={2}>
                    Número:
                  </Form.Label>
                  <Col sm={2}>
                    <Form.Control
                      type="number"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      placeholder="Ex. 100"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalCityState">
                  <Form.Label column sm={2}>
                    Cidade:
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="Ex. 100"
                      required
                    />
                  </Col>
                  <Form.Label column sm={2}>
                    Estado:
                  </Form.Label>
                  <Col sm={2}>
                    <Form.Control
                      type="text"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      placeholder="Ex. PB"
                      max={2}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column sm={2}>
                    Email:
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Informe o e-mail"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalPhoneOne">
                  <Form.Label column sm={2}>
                    Telefone 1:
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      value={phoneOne}
                      onChange={(e) => setPhoneOne(e.target.value)}
                      placeholder="Informe o telefone 1"
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formHorizontalPhoneTwol">
                  <Form.Label column sm={2}>
                    Telefone 2:
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      value={phoneTwo}
                      onChange={(e) => setPhoneTwo(e.target.value)}
                      placeholder="Informe telefone 2 (opcional)"
                    />
                  </Col>
                </Form.Group>

                <SubmitButton title={title} loading={loading.toString()} />
              </Form>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert className="mt-3" variant="success">
                  {success}
                </Alert>
              )}
            </BgForm>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
