import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #0d2344;
`;

export const Description = styled.span`
  text-align: justify;
`;
