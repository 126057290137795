import styled from 'styled-components';
import { Container, Row } from 'react-bootstrap';

export const Background = styled(Container)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Contact = styled(Row)`
  margin: 20px 5px;
`;

export const Logo = styled(Row)`
  text-align: center;
  margin-top: 70px;
`;

export const Menu = styled(Row)`
  margin: 40px 5px;

  a {
    margin-right: 15px;
    text-decoration: none;
  }
`;
