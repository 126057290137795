import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import News from './pages/News';
import NewsDetail from './pages/NewsDetail';
import SignIn from './pages/Admin/SignIn';
import Dashboard from './pages/Admin/Dashboard';
import Profile from './pages/Admin/Profile';
import Users from './pages/Admin/Users';
import InputUsers from './pages/Admin/Users/InputUsers';
import NewsAdmin from './pages/Admin/News';
import InputNews from './pages/Admin/News/InputNews';
import Comments from './pages/Admin/Comments';
import InputComments from './pages/Admin/Comments/InputComments';
import InputAboutUs from './pages/Admin/AboutUs';
import CardAboutUs from './pages/Admin/AboutUs/CardAboutUs';
import Contacts from './pages/Admin/Contacts';

import PrivateRoute from './components/Auth/PrivateRoute';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/sobre-nos" component={AboutUs} />
      <Route path="/novidades" component={News} />
      <Route path="/noticia/:id" component={NewsDetail} />
      <Route path="/login" component={SignIn} />
      <PrivateRoute path="/admin" exact component={Dashboard} />
      <PrivateRoute path="/admin/perfil" component={Profile} />
      <PrivateRoute path="/admin/usuarios" exact component={Users} />
      <PrivateRoute path="/admin/usuarios/add" component={InputUsers} />
      <PrivateRoute path="/admin/usuarios/upd/:userId" component={InputUsers} />
      <PrivateRoute path="/admin/novidades" exact component={NewsAdmin} />
      <PrivateRoute path="/admin/novidades/add" component={InputNews} />
      <PrivateRoute path="/admin/novidades/upd/:newsId" component={InputNews} />
      <PrivateRoute path="/admin/depoimentos" exact component={Comments} />
      <PrivateRoute path="/admin/depoimentos/add" component={InputComments} />
      <PrivateRoute
        path="/admin/depoimentos/upd/:commentId"
        component={InputComments}
      />
      <PrivateRoute path="/admin/sobre-nos" exact component={InputAboutUs} />
      <PrivateRoute path="/admin/sobre-nos/add" component={CardAboutUs} />
      <PrivateRoute path="/admin/sobre-nos/upd/:id" component={CardAboutUs} />
      <PrivateRoute path="/admin/contatos" component={Contacts} />
    </Switch>
  );
}
