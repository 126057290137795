import React from 'react';
import PropTypes from 'prop-types';
import { FaSpinner } from 'react-icons/fa';
import { SubmitButton as Button } from './styles';

export default function SubmitButton({ title, loading }) {
  const isLoading = loading === 'true';

  return (
    <Button loading={loading}>
      {isLoading ? (
        <span>
          <FaSpinner color="#FFF" size={14} /> Processando...
        </span>
      ) : (
        <span>{title}</span>
      )}
    </Button>
  );
}

SubmitButton.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.string,
};

SubmitButton.defaultProps = {
  title: PropTypes.string,
  loading: PropTypes.string,
};
