import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Form, Table, Row, Col, Alert } from 'react-bootstrap';
import { MdEdit, MdDelete } from 'react-icons/md';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { formatDate, resetMessages } from '~/util/Utils';
import { useCollection } from '~/hooks/useFirestore';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import BtForm from '~/components/Button';
import RemoveConfirmation from '~/components/RemoveConfirmation';
import SubmitButton from '~/components/SubmitButton';
import Pagination from '~/components/Pagination';

import { createAboutUs, updateAboutUs } from '~/controllers/AboutUsController';

import { Background, Body, Content, BgForm } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function AboutUs() {
  const [skeleton, setSkeleton] = useState(false);

  const docs = useCollection('cards', setSkeleton);
  const aboutus = useCollection('aboutus', setSkeleton);

  const [content, setContent] = useState('');
  const [id, setId] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [docsPerPage, setdocsPerPage] = useState(6);

  const indexOfLastPost = currentPage * docsPerPage;
  const indexOfFirstPost = indexOfLastPost - docsPerPage;
  const currentPosts = docs.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const title = 'Cadastrar Informações';

  const handleRemove = (cardId) => {
    setConfirmation(true);
    setRemoveId(cardId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    resetMessages(setError, setSuccess);
    setLoading(true);
    if (isUpdate) {
      const newContent = { id, content };
      return updateAboutUs(newContent, setError, setSuccess, setLoading);
    }
    return createAboutUs(content, setError, setSuccess, setLoading);
  };

  useEffect(() => {
    if (aboutus.length > 0) {
      setContent(aboutus[0].content);
      setId(aboutus[0].id);
      setIsUpdate(true);
    }
  }, [aboutus]);

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            {confirmation && (
              <RemoveConfirmation
                setConfirmation={setConfirmation}
                removeId={removeId}
                refName="cards"
                currentUser={null}
              />
            )}
            <Row>
              <Col md={6}>
                <Title title="GERENCIAMENTO DE CARDS" />
              </Col>
              <Col md={6}>
                <NavLink to="/admin/sobre-nos/add">
                  <BtForm title="CADASTRAR" />
                </NavLink>
              </Col>
            </Row>
            <BgForm>
              {docs.length > 0 ? (
                <>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Titulo</th>
                        <th>Criado</th>
                        <th>Modificado</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.map((card) => (
                        <tr key={card.id}>
                          <td>{card.title}</td>
                          <td>{formatDate(card.createdAt)}</td>
                          <td>{formatDate(card.updatedAt)}</td>
                          <td>
                            <Link to={`/admin/sobre-nos/upd/${card.id}`}>
                              <MdEdit size={18} color="#000" />{' '}
                            </Link>
                            <MdDelete
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleRemove(card.id)}
                              size={18}
                              color="#000"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination
                    postsPerPage={docsPerPage}
                    totalPosts={docs.length}
                    paginate={paginate}
                  />
                </>
              ) : (
                <p>Nenhum card cadastrado.</p>
              )}
            </BgForm>
            <hr />
            <Title title="INFORMAÇÕES DA TELA SOBRE NÓS" />
            <BgForm>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Detalhamento da firma: </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      setContent(editor.getData());
                    }}
                    data={content}
                  />
                </Form.Group>

                <SubmitButton title={title} loading={loading.toString()} />
              </Form>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert className="mt-3" variant="success">
                  {success}
                </Alert>
              )}
            </BgForm>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
