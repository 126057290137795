import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Image, Nav } from 'react-bootstrap';
import { MdEmail, MdCall } from 'react-icons/md';

import { useCollection } from '~/hooks/useFirestore';

import HeaderContactsSkeleton from '~/skeletons/HeaderContactsSkeleton';

import { Background, Contact, Logo, Menu } from './styles';
import GlobalStyle from '~/styles/global';

import logo from '~/assets/images/logo.png';

export default function Header() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneOne, setPhoneOne] = useState('');

  const contacts = useCollection('contacts', setLoading);

  useEffect(() => {
    setLoading(true);
    if (contacts.length > 0) {
      setEmail(contacts[0].email);
      setPhoneOne(contacts[0].phoneOne);
      setLoading(false);
    }
  }, [contacts]);
  return (
    <>
      <Background>
        <Contact>
          <Col sm={6} style={{ textAlign: 'center' }}>
            {loading && <HeaderContactsSkeleton />}
            {!loading && contacts && (
              <>
                <MdEmail size={16} />
                <span> {email}</span>
              </>
            )}
          </Col>
          <Col sm={6} style={{ textAlign: 'center' }}>
            {loading && <HeaderContactsSkeleton />}
            {!loading && contacts && (
              <>
                <MdCall size={16} />
                <span> {phoneOne}</span>
              </>
            )}
          </Col>
        </Contact>
        <Logo>
          <Col sm={12}>
            <Image
              src={logo}
              width={830}
              height={167}
              alt="Morais & Travassos"
              fluid
            />
          </Col>
        </Logo>
        <Menu>
          <Col md={12}>
            <Nav className="justify-content-center">
              <Nav.Item>
                <NavLink to="/">HOME</NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/novidades">NOVIDADES</NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink to="/sobre-nos">QUEM SOMOS</NavLink>
              </Nav.Item>
            </Nav>
          </Col>
        </Menu>
      </Background>
      <GlobalStyle />
    </>
  );
}
