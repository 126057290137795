import React, { useEffect, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { resetMessages } from '~/util/Utils';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import SubmitButton from '~/components/SubmitButton';

import {
  createComments,
  updateComments,
} from '~/controllers/CommentsController';
import { useDoc } from '~/hooks/useFirestore';

import { Background, Body, Content, BgForm } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function InputNews() {
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [author, setAuthor] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { commentId } = useParams();

  const docs = useDoc('comments', commentId);

  const btTitle = 'Cadastrar Depoimento';

  const handleClear = () => {
    setTitle('');
    setComment('');
    setAuthor('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    resetMessages(setError, setSuccess);
    setLoading(true);

    const contentObj = {
      title,
      comment,
      author,
    };
    if (isUpdate) {
      return updateComments(
        { commentId, ...contentObj },
        setError,
        setSuccess,
        setLoading
      );
    }
    return createComments(
      contentObj,
      setError,
      setSuccess,
      setLoading,
      handleClear
    );
  };

  useEffect(() => {
    if (docs.length > 0) {
      setIsUpdate(true);
      setTitle(docs[0].data().title);
      setAuthor(docs[0].data().author);
      setComment(docs[0].data().comment);
    }
  }, [docs, commentId]);

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            <Title title="CADASTRAR DEPOIMENTO" />
            <BgForm>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicTitle">
                  <Form.Label>Titulo: </Form.Label>
                  <Form.Control
                    type="Text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Informe o título"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicTitle">
                  <Form.Label>Autor: </Form.Label>
                  <Form.Control
                    type="Text"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    placeholder="Informe o autor"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicResume">
                  <Form.Label>Depoimento: </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <SubmitButton title={btTitle} loading={loading.toString()} />
              </Form>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert className="mt-3" variant="success">
                  {success}
                </Alert>
              )}
            </BgForm>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
