import { useState, useEffect } from 'react';
import { db } from '~/config/firebase';

export const useCollection = (collection, setLoading) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    setLoading(true);
    const unsub = db
      .collection(collection)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snap) => {
        const documents = [];
        snap.forEach((doc) => {
          if (doc.data().enable) {
            documents.push({ id: doc.id, ...doc.data() });
          }
        });
        setDocs(documents);
        setLoading(false);
      });
    return () => unsub();
  }, [collection, setLoading]);
  return docs;
};

export const useDoc = (collection, id) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (id === undefined) {
      return [];
    }
    return db
      .collection(collection)
      .doc(id)
      .get()
      .then((doc) => {
        const documents = [];
        documents.push(doc);
        setDocs(documents);
      });
  }, [collection, id]);
  return docs;
};

export const useQuery = (collection, filter, value) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const unsub = db
      .collection(collection)
      .where(filter, '==', value)
      .where('enable', '==', true)
      .onSnapshot((snap) => {
        const documents = [];
        snap.forEach((doc) => {
          documents.push({ id: doc.id, ...doc.data() });
        });
        setDocs(documents);
      });

    return () => unsub();
  }, [collection, filter, value]);
  return docs;
};

export default { useCollection, useDoc };
