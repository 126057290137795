import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Row, Col } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';

import { formatDate } from '~/util/Utils';
import { useAuth } from '~/contexts/AuthContext';
import { useCollection, useQuery } from '~/hooks/useFirestore';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import Button from '~/components/Button';
import RemoveConfirmation from '~/components/RemoveConfirmation';
import Pagination from '~/components/Pagination';

import { Background, Body, Content, BgUsers, Message } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function Dashboard() {
  const { currentUser } = useAuth();
  const collection = 'users';

  const [skeleton, setSkeleton] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [userType, setUserType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [docsPerPage, setdocsPerPage] = useState(10);

  const docs = useCollection(collection, setSkeleton);
  const userLogged = useQuery(collection, 'uid', currentUser.uid);

  const indexOfLastPost = currentPage * docsPerPage;
  const indexOfFirstPost = indexOfLastPost - docsPerPage;
  const currentPosts = docs.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRemove = (id) => {
    setConfirmation(true);
    setRemoveId(id);
  };

  useEffect(() => {
    if (userLogged.length > 0) {
      setUserType(userLogged[0].type);
    }
  }, [userLogged]);

  return (
    <>
      <Background fluid>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={12}>
            {confirmation && (
              <RemoveConfirmation
                setConfirmation={setConfirmation}
                removeId={removeId}
                refName={collection}
                blockUser
              />
            )}
            <Row>
              <Col md={6}>
                <Title title="GERECIAMENTO DE USUARIOS" />
              </Col>
              <Col md={6}>
                <NavLink to="/admin/usuarios/add">
                  <Button title="CADASTRAR" />
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <BgUsers>
                  {docs.length > 0 ? (
                    <>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Tipo </th>
                            <th>Criado </th>
                            <th>Atualizado</th>
                            <th>Opções</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPosts
                            .filter((doc) => doc.uid !== currentUser.uid)
                            .map((user) => (
                              <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.type}</td>
                                <td>{formatDate(user.createdAt)}</td>
                                <td>{formatDate(user.updatedAt)}</td>
                                <td>
                                  {(userType !== '' &&
                                    userType === 'Administrador') ||
                                    (userType === 'Webmaster' && (
                                      <MdDelete
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleRemove(user.id)}
                                        size={18}
                                        color="#000"
                                      />
                                    ))}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Pagination
                        postsPerPage={docsPerPage}
                        totalPosts={docs.length}
                        paginate={paginate}
                      />
                    </>
                  ) : (
                    <Message>Nenhum usuário cadastrado</Message>
                  )}
                </BgUsers>
              </Col>
            </Row>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
