import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from 'react-bootstrap';

import { removeDocuments, removeUser } from '~/controllers/RemoveController';
import { disableUser } from '~/controllers/UserController';
import { useAuth } from '~/contexts/AuthContext';

export default function RemoveConfirmation({
  setConfirmation,
  removeId,
  refName,
  currentUser,
  password,
  blockUser,
}) {
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { getCredential } = useAuth();

  const handleClose = () => {
    setShow(false);
    setConfirmation(false);
    setDisabled(false);
  };
  const handleShow = () => setShow(true);

  const handleRemove = () => {
    setDisabled(true);
    const doc = {
      id: removeId,
      ref: refName,
    };
    if (currentUser) {
      return currentUser
        .reauthenticateWithCredential(
          getCredential(currentUser.email, password)
        )
        .then(() => {
          removeDocuments(doc, setError, setSuccess);
          removeUser(currentUser, setError);
        })
        .catch((err) => {
          setError(`Senha informada inválida. ${err}`);
        });
    }
    if (blockUser) {
      return disableUser(doc, setError, setSuccess);
    }
    return removeDocuments(doc, setError, setSuccess);
  };

  useEffect(() => {
    if (removeId !== undefined && refName !== undefined) {
      handleShow();
    }
  }, [setConfirmation, removeId, refName]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Atenção</Modal.Title>
      </Modal.Header>
      <Modal.Body>Você realmente deseja remover este item?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button disabled={disabled} variant="danger" onClick={handleRemove}>
          Remover
        </Button>
      </Modal.Footer>
      {error && (
        <Alert className="ml-3 mr-3" variant="danger">
          {error}
        </Alert>
      )}
      {success && (
        <Alert className="ml-3 mr-3" variant="success">
          {success}
        </Alert>
      )}
    </Modal>
  );
}

RemoveConfirmation.propTypes = {
  setConfirmation: PropTypes.func,
  removeId: PropTypes.string,
  refName: PropTypes.string,
  password: PropTypes.string,
  currentUser: PropTypes.shape(),
  blockUser: PropTypes.bool,
};

RemoveConfirmation.defaultProps = {
  setConfirmation: PropTypes.func,
  removeId: PropTypes.string,
  refName: PropTypes.string,
  password: '',
  currentUser: null,
  blockUser: false,
};
