import React from 'react';

import { LoadingSkeleton } from '~/skeletons/LoadingSkeleton';

import { HeaderContacts } from './styles';

export default function HeaderContactsSkeleton() {
  return (
    <>
      <HeaderContacts>
        <LoadingSkeleton borderRadius={2} />
      </HeaderContacts>
    </>
  );
}
