import React from 'react';
import { Col } from 'react-bootstrap';

import { LoadingSkeleton } from '~/skeletons/LoadingSkeleton';

import { AboutUs } from './styles';

export default function CardSkeleton() {
  const details = [];
  for (let i = 0; i < 15; i += 1) {
    details.push(<LoadingSkeleton key={i} />);
  }
  return (
    <>
      <Col md={6}>
        <AboutUs>{details}</AboutUs>
      </Col>
    </>
  );
}
