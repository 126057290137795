import styled, { keyframes, css } from 'styled-components';

import { Container, Button } from 'react-bootstrap';

export const Background = styled(Container)`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  background: #0d2344;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-top: 30px;

  h1 {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
  }

  hr {
    background: #fff;
    margin: 10px 0 50px 0;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360g);
  }
`;

export const SubmitButton = styled(Button).attrs((props) => ({
  type: 'submit',
  disabled: props.loading,
}))`
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${(props) =>
    props.loading &&
    css`
      svg {
        animation: ${rotate} 2s linear infinite;
      }
    `}
`;
