import React, { useEffect, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { resetMessages, validateImage } from '~/util/Utils';

import Navbar from '~/components/Navbar';
import Sidebar from '~/components/Sidebar';
import Title from '~/components/Title';
import ProgressBar from '~/components/ProgressBar';
import SubmitButton from '~/components/SubmitButton';

import { createCard, updateCard } from '~/controllers/CardsController';
import { useDoc } from '~/hooks/useFirestore';

import { Background, Body, Content, BgForm } from './styles';
import GlobalStyle from '~/styles/globalAdmin';

export default function InputNews() {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [docId, setDocId] = useState('');
  const [btTitle, setBtTitle] = useState('Cadastrar Card');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { id } = useParams();
  const refName = 'cards';

  const doc = useDoc(refName, id);

  const handleClear = () => {
    setFile(null);
    setTitle('');
    setContent('');
    setDocId('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    resetMessages(setError, setSuccess);
    setLoading(true);

    const contentObj = {
      title,
      content,
    };

    if (isUpdate) {
      return updateCard(
        { id, ...contentObj },
        setError,
        setSuccess,
        setLoading,
        setDocId
      );
    }
    return createCard(
      contentObj,
      setError,
      setSuccess,
      setLoading,
      setDocId,
      handleClear
    );
  };

  const handleImage = (e) => {
    const selected = e.target.files[0];
    resetMessages(setError, setSuccess);
    return validateImage(selected, setFile, setError);
  };

  useEffect(() => {
    if (doc.length > 0) {
      setBtTitle('Atualizar Card');
      setIsUpdate(true);
      setTitle(doc[0].data().title);
      setContent(doc[0].data().content);
    }
  }, [doc]);

  return (
    <>
      <Background>
        <Navbar />
        <Body>
          <Sidebar />
          <Content col={10}>
            <Title title="CADASTRAR NOVO CARD" />
            <BgForm>
              <Form onSubmit={handleSubmit}>
                <Form.Label>Imagem do Card: </Form.Label>

                <Form.Group>
                  <Form.Control type="file" onChange={handleImage} />
                </Form.Group>

                {docId && file && (
                  <ProgressBar
                    file={file}
                    docId={docId}
                    setFile={setFile}
                    refName={refName}
                  />
                )}

                <Form.Group controlId="formBasicTitle">
                  <Form.Label>Titulo do Card: </Form.Label>
                  <Form.Control
                    type="Text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Informe seu nome"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Resumo do Card: </Form.Label>
                  <Form.Control
                    as="textarea"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <SubmitButton title={btTitle} loading={loading.toString()} />
              </Form>
              {error && (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert className="mt-3" variant="success">
                  {success}
                </Alert>
              )}
            </BgForm>
          </Content>
        </Body>
      </Background>
      <GlobalStyle />
    </>
  );
}
