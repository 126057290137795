import firebase from 'firebase/app';
import { db } from '../config/firebase';

const ref = db.collection('comments');

export function createComments(
  content,
  setError,
  setSuccess,
  setLoading,
  handleClear
) {
  try {
    return ref
      .add({
        author: content.author,
        title: content.title,
        comment: content.comment,
        enable: true,
        createdAt: new Date().toISOString(),
        updateAt: '',
      })
      .then(() => {
        setLoading(false);
        handleClear();
        return setSuccess('Depoimento cadastrado com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao cadastrar o depoimento. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao cadastrar o depoimento. ${err}`);
  }
}

export function updateComments(content, setError, setSuccess, setLoading) {
  const newRef = db.collection('comments').doc(content.commentId);
  try {
    return newRef
      .update({
        author: content.author,
        title: content.title,
        comment: content.comment,
        updatedAt: new Date().toISOString(),
      })
      .then(() => {
        setLoading(false);
        return setSuccess('Depoimento atualizado com sucesso.');
      })
      .catch((err) => {
        setLoading(false);
        return setError(`Ocorreu um erro ao atualizar o depoimento. ${err}`);
      });
  } catch (err) {
    setLoading(false);
    return setError(`Ocorreu um erro ao atualizar o depoimento. ${err}`);
  }
}
