import React from 'react';
import { Col } from 'react-bootstrap';

import { LoadingSkeleton } from '~/skeletons/LoadingSkeleton';

import { FooterTitle, FooterContent } from './styles';

const contentSkeletons = [];

for (let i = 0; i < 8; i += 1) {
  contentSkeletons.push(
    <FooterContent key={i}>
      <LoadingSkeleton borderRadius={3} key={i} />
    </FooterContent>
  );
}

export default function FooterContactsSkeleton() {
  return (
    <Col md={4}>
      <FooterTitle>
        <LoadingSkeleton borderRadius={3} />
      </FooterTitle>
      {contentSkeletons}
    </Col>
  );
}
