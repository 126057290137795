import React, { useContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';
import { auth } from '../config/firebase';

const Context = React.createContext();

export function useAuth() {
  return useContext(Context);
}
export function AuthContext({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signUp(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function LogIn(email, password) {
    return auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return auth.signInWithEmailAndPassword(email, password);
      });
  }

  function LogOut() {
    return auth.signOut();
  }

  function getCredential(email, password) {
    return firebase.auth.EmailAuthProvider.credential(email, password);
  }

  function updateEmail(user, data, setError) {
    try {
      return user
        .reauthenticateWithCredential(
          getCredential(data.emailOld, data.passwordOld)
        )
        .then(() => {
          user.updateEmail(data.email);
        })
        .catch((err) => {
          return setError(`Ocorreu um erro ao alterar o e-mail. ${err}`);
        });
    } catch (err) {
      return setError(`Ocorreu um erro ao alterar o e-mail. ${err}`);
    }
  }

  function updatePassword(user, data, setError) {
    try {
      return user
        .reauthenticateWithCredential(
          getCredential(data.emailOld, data.passwordOld)
        )
        .then(() => {
          user.updatePassword(data.password);
        })
        .catch((err) => {
          return setError(`Ocorreu um erro ao alterar a senha. ${err}`);
        });
    } catch (err) {
      return setError(`Ocorreu um erro ao alterar a senha. ${err}`);
    }
  }

  useEffect(() => {
    const unsubscriber = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscriber;
  }, []);

  const value = {
    currentUser,
    signUp,
    LogIn,
    LogOut,
    updateEmail,
    updatePassword,
    getCredential,
  };
  return (
    <>
      <Context.Provider value={value}>{!loading && children}</Context.Provider>
    </>
  );
}

AuthContext.propTypes = {
  children: PropTypes.element,
};

AuthContext.defaultProps = {
  children: PropTypes.element,
};
