import React from 'react';
import { NavLink } from 'react-router-dom';

import { Nav } from 'react-bootstrap';
import {
  MdHome,
  MdPersonPin,
  MdReceipt,
  MdInfoOutline,
  MdContactMail,
  MdComment,
} from 'react-icons/md';
import { Menu } from './styles';

export default function Sidebar() {
  return (
    <Menu md={2}>
      <Nav defaultActiveKey="/admin/dashboard" className="flex-column">
        <NavLink to="/admin">
          <MdHome size={16} color="#fff" /> Dashboard
        </NavLink>
        <NavLink to="/admin/usuarios">
          <MdPersonPin size={16} color="#fff" /> Usuários
        </NavLink>
        <NavLink to="/admin/novidades">
          <MdReceipt size={16} color="#fff" /> Notícias
        </NavLink>
        <NavLink to="/admin/depoimentos">
          <MdComment size={16} color="#fff" /> Depoimentos
        </NavLink>
        <NavLink to="/admin/sobre-nos">
          <MdInfoOutline size={16} color="#fff" /> Sobre Nós
        </NavLink>
        <NavLink to="/admin/contatos">
          <MdContactMail size={16} color="#fff" /> Contatos
        </NavLink>
      </Nav>
    </Menu>
  );
}
