import styled from 'styled-components';

import { Container, Row, Col } from 'react-bootstrap';

export const Background = styled(Container)`
  max-width: 100%;
  background-color: #0d2344;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: inline-flex;
  padding: 10px 0;
`;

export const Line = styled(Row)`
  justify-content: space-around;

  h1 {
    color: #eee;
    font-size: 24px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    padding-top: 80px;
    text-align: justify;
  }

  hr {
    border: 1px solid #eee;
    width: 300px;
  }

  p {
    margin-top: 15px;
    color: #eee;
    text-align: justify;
  }

  img {
    justify-content: right;
  }
`;

export const Column = styled(Col)`
  width: auto;
`;
