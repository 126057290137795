import React from 'react';
import PropTypes from 'prop-types';

import { Number, NavBar, Button } from './styles';

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i += 1) {
    pageNumbers.push(i);
  }
  return (
    <NavBar>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <Number key={number} className="page-item">
            <Button
              onClick={() => paginate(number)}
              type="button"
              className="page-link"
            >
              {number}
            </Button>
          </Number>
        ))}
      </ul>
    </NavBar>
  );
};

export default Pagination;

Pagination.propTypes = {
  postsPerPage: PropTypes.number.isRequired,
  totalPosts: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
};
